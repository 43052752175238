import emailjs from "@emailjs/browser";
import React, { useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";

const Signup = () => {
  emailjs.init("cS9Pd_2IyUfnJVDmF");
  const [email, setEmail] = useState(() => "");
  const [loading, setLoading] = useState(() => false);
  const [token, setToken] = useState("");
  const serviceId = "service_4rro2sn";
  const templateId = "template_r19lgdt";

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (email !== "") {
      setLoading(true);
      emailjs
        .send(serviceId, templateId, {
          email: email,
          "g-recaptcha-response": token,
        })
        .then(() => {
          toast("Successfully sent!", {
            position: "bottom-right",
            autoClose: 2000,
          });
          setLoading(false);
          setEmail("");
        })
        .catch((err) => {
          console.log(err, "error");
          setLoading(false);
          toast.error(err.text, {
            position: "bottom-right",
            autoClose: 2000,
          });
        });
    }
  };
  return (
    <>
      {" "}
      <form onSubmit={(e) => onSubmitHandler(e)} className="w-100 d-flex ">
        <input
          type="email"
          placeholder="Email address"
          required
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          className="w-100 border-0 p-0 bg-transparent ps-3 font-xs"
        />
        <button
          type="submit"
          className="sign-up-btn color-white font-xs text-nowrap font-medium"
        >
          {loading ? "Loading..." : "SIGN UP"}
        </button>
      </form>
      <GoogleReCaptcha
        onVerify={(token) => {
          setToken(token);
        }}
      />
    </>
  );
};

export default Signup;
