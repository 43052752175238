import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useParallax } from "react-scroll-parallax";

const Hero = () => {
  const [playVideo, setPlayVideo] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setPlayVideo(true);
    }, 2000);
  }, []);

  const { ref } = useParallax({ speed: 10 });

  return (
    <>
      <section
        className="overflow-hidden hero_section position-relative"
        ref={ref}
      >
        <div id="react-player-value" className="position-relative bg_img_after">
          <img
            className="w-100 object_fit_cover"
            height="100%"
            style={{ display: playVideo ? "none" : "block" }}
            src="https://vz-31b52218-178.b-cdn.net/f23b0d1f-1049-47d3-a3bf-30dcfcb3b384/thumbnail_6647bcf6.jpg"
            alt="thumbnail"
          />
          <ReactPlayer
            style={{ display: playVideo ? "block" : "none" }}
            className="react-player"
            url="https://vz-31b52218-178.b-cdn.net/f23b0d1f-1049-47d3-a3bf-30dcfcb3b384/playlist.m3u8"
            width="100%"
            playing={playVideo}
            loop={true}
            playsinline
            muted={true}
            height="100%"
          />

          <div className="position-absolute bottom-0 w-100 h-100  d-flex justify-content-end flex-column homepage_hero_text">
            <div className="pb-sm-5 mb-sm-5 container">
              <h1 className="text-white text-sm-start text-center ff_josfin">
                <span
                  className="d-inline-block pe-2"
                  data-aos="fade-up-right"
                  data-aos-duration="800"
                  data-aos-delay="200"
                >
                 OUR
                </span>
                <span
                  className="d-inline-block px-2"
                  data-aos="fade-up-right"
                  data-aos-duration="800"
                  data-aos-delay="600"
                >
                  SAFE
                </span>
                <span
                  className="d-inline-block px-2"
                  data-aos="fade-up-right"
                  data-aos-duration="800"
                  data-aos-delay="1000"
                >
                  SHIELD
                </span>
              </h1>
              <p
                data-aos="fade-right"
                data-aos-delay="1500"
                data-aos-duration="1200"
                className="text-white text-sm-start text-center ff_josfin text-uppercase font-lg fw-light"
              >
                SECURING OUR VITAL INFRASTRUCTURE
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
