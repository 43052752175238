import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";
import { ScrollText } from "./common/Icons";

const ContactHero = () => {
  const { ref } = useParallax({ speed: 25 });
  const navigate = useNavigate();
  const [PlayingVedio, setPlayingVedio] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setPlayingVedio(true);
    }, 2000);
  }, []);

  return (
    <>
      <section
        className="overflow-hidden position-relative hero_section"
        ref={ref}
      >
        <div className="position-absolute hero_scroll_text">
          <ScrollText />
        </div>
        <div
          id="react-player-value"
          className="position-relative bg_img_contact"
        >
          <img
            className="w-100 object_fit_cover"
            height="100%"
            style={{ display: PlayingVedio ? "none" : "block" }}
            src="https://vz-31b52218-178.b-cdn.net/2b3122cb-35d6-4b18-bf45-f536a56dc01f/thumbnail.jpg"
            alt="thumbnail"
          />
          <ReactPlayer
            style={{ display: PlayingVedio ? "block" : "none" }}
            className="react-player"
            url="https://vz-31b52218-178.b-cdn.net/2b3122cb-35d6-4b18-bf45-f536a56dc01f/playlist.m3u8"
            width="100%"
            playing={PlayingVedio}
            loop={true}
            playsinline
            muted={true}
            height="100%"
          />
          <div className="position-absolute bottom-0 w-100 h-100  d-flex justify-content-end flex-column homepage_hero_text">
            <div className="pb-sm-5 mb-sm-5 container">
              <h2
                className="text-white text-sm-start text-center font-2xl fw-lighter ff_josfin"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="800"
              >
                CONTACT CYBORYX
              </h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactHero;
