import Accordion from "react-bootstrap/Accordion";
import { solutionData } from "./common/Helper";

function SolutionFaq() {
  return (
    <Accordion className="board-of-director-accordion">
      {solutionData.map((obj, index) => (
        <Accordion.Item eventKey={index} key={index} className="bg-transparent">
          <Accordion.Header
            className={`${obj.image} position-relative bg-transparent`}
          >
            <span>{obj.title}</span>
          </Accordion.Header>
          <Accordion.Body className="p-4">{obj.desc}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default SolutionFaq;
