// import img1 from "../../assets/sector/oil.jpg";
// import img2 from "../../assets/sector/electricity.jpg";
// import img3 from "../../assets/sector/defence.jpg";
// import img4 from "../../assets/sector/interior.jpg";
// import img5 from "../../assets/sector/transport.jpg";
import img6 from "../../assets/images/webp/health.webp";

import {
  EconomicIcon,
  EnvirIcon,
  FacebookIcon,
  HumanIcon,
  InstaIcon,
  LinkedIcon,
  SnapIcon,
  TikTokIcon,
  TwitterIcon,
  VideoIcon,
  SocialIcon,
  OilIcon,
  WaterIcon,
  MinistrDefence,
  Interior,
  TransportIcon,
  PublicHealth,
} from "./Icons";

export const footerlinks = [
  {
    text: "SOLUTIONS",
    link: "/#solutions",
  },
  {
    text: "SECTORS",
    link: "/#sectors",
  },
  {
    text: "PARTNERS",
    link: "/contact",
  },
  {
    text: "COMPANY",
    link: "/#about",
  },
  {
    text: "Contact Us",
    link: "/contact",
  },
  {
    text: "Qatar National Vision 2030",
    link: "/qatar",
  },
];
export const footerlinksmobile = [
  {
    text: "SOLUTIONS",
    link: "/#solution",
  },
  {
    text: "SECTORS",
    link: "/#sectors",
  },
  {
    text: "PARTNERS",
    link: "/contact",
  },
  {
    text: "COMPANY",
    link: "/#about",
  },
  {
    text: "Contact Us",
    link: "/contact",
  },
  {
    text: "Qatar National Vision 2030",
    link: "/qatar",
  },
];

export const FooterIcon = [
  {
    img: <VideoIcon />,
    link: "https://www.youtube.com/",
  },
  {
    img: <FacebookIcon />,
    link: "https://www.facebook.com/",
  },
  {
    img: <LinkedIcon />,
    link: "https://www.linkedin.com/",
  },
  {
    img: <InstaIcon />,
    link: "https://www.instagram.com/",
  },
  {
    img: <SnapIcon />,
    link: "https://www.snapchat.com",
  },
  {
    img: <TikTokIcon />,
    link: "https://www.ticktok.com/",
  },
  {
    img: <TwitterIcon />,
    link: "https://twitter.com/",
  },
];
export const standardCardData = [
  {
    colClass_second: "col-lg-4 col-md-6 col-12",
    parentClass: "",
    videoUrl:
      "https://vz-31b52218-178.b-cdn.net/cf0c6118-7bdb-462e-b856-64941b7bdd53/playlist.m3u8",
    para1: "HARDENED VITAL INFRASTRUCTURE SECURITY",
    para2: "HARDENED VITAL INFRASTRUCTURE SECURITY",
    para3:
      "Cyboryx brings deep domain expertise and solutions to effectively tackle growing burden of today's industrial cybersecurity challenges.",
  },
  {
    colClass_second: "col-lg-4 col-md-6 col-12 mt-4 mt-sm-0",
    parentClass: "",

    videoUrl:
      "https://vz-31b52218-178.b-cdn.net/0ca16fbd-22a5-4a34-9dda-652f85cc13c9/playlist.m3u8",
    para1: "STATE OF THE ART SECURITY OPERATION CENTERS",
    para2: "STATE OF THE ART SECURITY OPERATION CENTERS",
    para3:
      "Cyboryx's sector specific SOC brings hyper-vigilant 24/7 active monitoring Security Operation Center tailored for visibility and resiliency",
  },
  {
    colClass_second: "col-lg-4 col-sm-6 col-12 mt-4 mt-lg-0",
    parentClass: "",

    videoUrl:
      "https://vz-31b52218-178.b-cdn.net/54f815b0-11e7-4711-a3bc-a77aa997705b/playlist.m3u8",

    para1: "INTERNATIONAL ICS SECURITY COMPLIANCE ASSURANCE",
    para2: "INTERNATIONAL ICS SECURITY COMPLIANCE ASSURANCE",
    para3:
      "Cyboryx’s Vast International OT/IoT Experience Combined With The Industry’s Top Workforce Ensures World Class Industrial Compliance.",
  },
];

export const SocialIconData = [
  {
    image: <HumanIcon />,
    text: "human development",
  },
  {
    image: <SocialIcon />,
    text: "Social development",
  },
  {
    image: <EconomicIcon />,
    text: "economic development",
  },
  {
    image: <EnvirIcon />,
    text: "environmental development",
  },
];

export const IndustrSector = [
  {
    img: <OilIcon />,
    text: "OIL & GAS",
    bg_image: "oil",
  },
  {
    img: <WaterIcon />,
    text: "WATER & ELECTRICITY",
    bg_image: "electricity",
  },
  {
    img: <MinistrDefence />,
    text: "DEFENSE",
    bg_image: "defence",
  },

  {
    img: <Interior />,
    text: "SMART CITY",
    bg_image: "interrior",
  },
  {
    img: <TransportIcon />,
    text: "TRANSPORTATION",
    bg_image: "transport",
  },
  {
    img: <PublicHealth />,
    text: "HEALTHCARE",
    bg_image: "health",
  },
];
export const OurGoalsData = [
  {
    number: "01",
    para: "NATIONAL SECURITY",
    para2:
      "We equip Qatar with the best ICS cybersecurity solutions and protect its vital infrastructure against adverse cyber attacks.",
  },
  {
    number: "02",
    para: "SUSTAINABLE FOOTPRINT",
    para2:
      "We leave a sustainable footprint by developing our own Qatari ICS engineering workforce and tailored industrial security integrations.",
  },
  {
    number: "03",
    para: "EDUCATION AND EXPERTISE",
    para2:
      "We partner up with the top international educational institutions that specialize in OT automation and ICS Security.",
  },
  {
    number: "04",
    para: "QATAR NATIONAL VISION 2030 ",
    para2:
      "Our Strength lies in our adaptability towards the National vision. We continuously align ourselves with Qatar’s National Vision 2030.",
  },
];
export const OurGoalsFaqData = [
  {
    number: "01",
    para: "NATIONAL SECURITY",
    year: "",
    para2:
      "We equip Qatar with the best ICS cybersecurity solutions and protect its vital infrastructure against adverse cyber attacks.",
  },
  {
    number: "02",
    para: "SUSTAINABLE FOOTPRINT",
    year: "",
    para2:
      "We leave a sustainable footprint by developing our own Qatari ICS engineering workforce and tailored industrial security integrations.",
  },
  {
    number: "03",
    para: "EDUCATION AND EXPERTISE",
    year: "",
    para2:
      "We partner up with the top international educational institutions that specialize in OT automation and ICS Security.",
  },
  {
    number: "04",
    para: "QATAR NATIONAL VISION ",
    year: "2030",
    para2:
      "Our Strength lies in our adaptability towards the National vision. We continuously align ourselves with Qatar’s National Vision 2030.",
  },
];

export const HeaderData = [
  {
    text: "Solutions",
    link: "#solutions",
  },
  {
    text: "Sectors",
    link: "#sectors",
  },
  {
    text: "Partners",
    link: "/contact",
  },
  {
    text: "Company",
    link: "#about",
  },
];
export const HeaderDataMobile = [
  {
    text: "Solutions",
    link: "#solution",
  },
  {
    text: "Sectors",
    link: "#sectors",
  },
  {
    text: "Partners",
    link: "/contact",
  },
  {
    text: "Company",
    link: "#about",
  },
];

export const cyberSecurityData = [
  {
    parentCard: [
      {
        number: "01",
        text: " Seamless Integration",
      },
      {
        number: "02",
        text: " Sector Specific SOC",
      },
    ],
  },
  {
    parentCard: [
      {
        number: "03",
        text: "Secure Field Operations",
      },
      {
        number: "04",
        text: "Dedicated Data Centers",
      },
    ],
  },
  {
    parentCard: [
      {
        number: "05",
        text: "Industrial Regulations Compliance",
      },
      {
        number: "06",
        text: "Dedicated Incident Response Team",
      },
    ],
  },
];
export const solutionData = [
  {
    title: "DETAILED ASSET VISIBILITY & MANAGEMENT ",
    desc: `Manage all your assets and derive all the details on a multi-pane dashboard`,
    image: "true_card_bg ",
  },
  {
    title: "VULNERABILITY MANAGEMENT",
    desc: `Stay On Top Of All Your Current Vulnerabilities And Get
Detailed Remediation Plans.`,
    image: "vulnerabilty_card_bg",
  },
  {
    title: "RESILIENT RESPONSE PROCESS",
    desc: `Investigate, Respond To Incidents And Contain Any Level Of
Attack With Confidence`,
    image: "resilent_card_bg",
  },
  {
    title: "AI/ML BASED THREAT INTELLIGENCE",
    desc: `Assess Cyber And Physical Threats And Threat Actors To
Mitigate Potential Attacks`,
    image: "based_card_bg",
  },
];
