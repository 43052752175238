import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FooterLogoIcon } from "../common/Icons";
import { FooterIcon, footerlinks, footerlinksmobile } from "./Helper";
import Signup from "./Signup";

const Footer = () => {
  const navigate = useNavigate();
  const getCurrentYear = new Date().getFullYear();

  return (
    <>
      <section className="bg-light-white">
        <div className="container-fluid px-3 px-sm-5 py-4">
          <div className="row justify-content-between align-items-center px-3 px-sm-0">
            <div className="col-lg-auto">
              <h6 className="font-xs font-normal text-center text-lg-start mb-0">
                Get news and updates
              </h6>
            </div>
            <div className="col-lg-auto my-2 my-md-2 my-lg-0 ">
              <h6 className="font-xs font-normal color-black text-center text-lg-start opacity-07 mb-0">
                Sign up for the latest news on how we are shaping a new future.
              </h6>
            </div>
            <div className="col-12 col-lg-5 col-xl-6 d-flex justify-between input-common-clases p-1 sign_up_active">
              <Signup />
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="footer_bg pt-5">
          <div className="container-fluid px-3 px-sm-5 pt-lg-4">
            <div className="text-center">
              <span onClick={() => navigate("/")} className="coursor-pointer">
                <FooterLogoIcon />
              </span>
            </div>
            <div className=" d-lg-flex justify-content-center mt-sm-5 mt-3 flex-sm-row flex-column d-none">
              <span className="text-white d-inline-block mx-3 cursor-pointer font-sm pt-sm-0 pt-3 text-sm-start text-center">
                <a
                  href="/#solutions"
                  className="color-wwhite text-decoration-none footer_link position-relative text-uppercase"
                >
                  SOLUTIONS
                </a>
              </span>
              <span className="text-white d-inline-block mx-3 cursor-pointer font-sm pt-sm-0 pt-3 text-sm-start text-center">
                <a
                  href="/#sectors"
                  className="color-wwhite text-decoration-none footer_link position-relative text-uppercase"
                >
                  SECTORS
                </a>
              </span>
              <span className="text-white d-inline-block mx-3 cursor-pointer font-sm pt-sm-0 pt-3 text-sm-start text-center">
                <Link
                  to="/contact"
                  className="color-wwhite text-decoration-none footer_link position-relative text-uppercase"
                >
                  PARTNERS
                </Link>
              </span>
              <span className="text-white d-inline-block mx-3 cursor-pointer font-sm pt-sm-0 pt-3 text-sm-start text-center">
                <a
                  href="/#about"
                  className="color-wwhite text-decoration-none footer_link position-relative text-uppercase"
                >
                  COMPANY
                </a>
              </span>
              <span className="text-white d-inline-block mx-3 cursor-pointer font-sm pt-sm-0 pt-3 text-sm-start text-center">
                <a
                  href="/contact"
                  className="color-wwhite text-decoration-none footer_link position-relative text-uppercase"
                >
                  CONTACT US
                </a>
              </span>
              <span className="text-white d-inline-block mx-3 cursor-pointer font-sm pt-sm-0 pt-3 text-sm-start text-center">
                <Link
                  to="/qatar"
                  className="color-wwhite text-decoration-none footer_link position-relative text-uppercase"
                >
                  Qatar National Vision 2030
                </Link>
              </span>
            </div>
            <div className=" d-flex justify-content-center mt-sm-5 mt-3 flex-sm-row flex-column d-lg-none">
              <span className="text-white d-inline-block pt-3 mx-1 cursor-pointer font-sm  text-sm-start text-center">
                <a
                  href="/#solutions"
                  className="color-wwhite text-decoration-none footer_link position-relative text-uppercase"
                >
                  SOLUTIONS
                </a>
              </span>
              <span className="text-white d-inline-block pt-3 mx-1 cursor-pointer font-sm  text-sm-start text-center">
                <a
                  href="/#sectors"
                  className="color-wwhite text-decoration-none footer_link position-relative text-uppercase"
                >
                  SECTORS
                </a>
              </span>
              <span className="text-white d-inline-block pt-3 mx-1 cursor-pointer font-sm  text-sm-start text-center">
                <Link
                  to="/contact"
                  className="color-wwhite text-decoration-none footer_link position-relative text-uppercase"
                >
                  PARTNERS
                </Link>
              </span>
              <span className="text-white d-inline-block pt-3 mx-1 cursor-pointer font-sm  text-sm-start text-center">
                <a
                  href="/#about"
                  className="color-wwhite text-decoration-none footer_link position-relative text-uppercase"
                >
                  COMPANY
                </a>
              </span>
              <span className="text-white d-inline-block pt-3 mx-1 cursor-pointer font-sm  text-sm-start text-center">
                <Link
                  to="/contact"
                  className="color-wwhite text-decoration-none footer_link position-relative text-uppercase"
                >
                  CONTACT US
                </Link>
              </span>
              <span className="text-white d-inline-block pt-3 mx-1 cursor-pointer font-sm  text-sm-start text-center">
                <Link
                  to="/qatar"
                  className="color-wwhite text-decoration-none footer_link position-relative text-uppercase"
                >
                  Qatar National Vision 2030
                </Link>
              </span>
            </div>
            <div className="footer_bg_line mt-5"></div>
            <div className=" d-flex justify-content-between align-items-center flex-sm-row flex-column">
              <a
                href="#"
                className="text-white ff_josfin font-sm pt-sm-0 pt-3 text-decoration-none position-relative"
              >
                Follow Cyboryx :
              </a>
              <div className="d-flex mt-sm-5 my-4">
                {FooterIcon.map((val, i) => {
                  return (
                    <a
                      href={val.link}
                      className="footer_icon_hover transition_03s"
                      key={i}
                      target="_blank"
                    >
                      <span className="d-inline mx-1 ">{val.img}</span>
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="d-flex flex-xl-row flex-column justify-content-between pb-4 align-items-center pt-3">
              <div className="d-flex justify-content-center">
                <a
                  target="_blank"
                  href="https://policies.google.com/privacy"
                  className="color-light-white font-xxs text-decoration-none footer_link position-relative"
                >
                  Privacy policy
                </a>
                <a
                  target="_blank"
                  href="https://policies.google.com/terms"
                  className="mx-3 color-light-white font-xxs text-decoration-none footer_link position-relative"
                >
                  Terms of use
                </a>
                <a
                  href="#"
                  className="color-light-white font-xxs text-decoration-none footer_link position-relative"
                >
                  Cookie policy
                </a>
              </div>
              <div className="pt-xl-0 pt-4 d-flex flex-sm-row flex-column justify-content-between">
                <span className="color-light-white font-xxs pt-sm-0 pt-3 text-sm-start text-center pb-3">
                  <span className=" opacity-07">
                    This site is protected by reCAPTCHA and the Google &nbsp;
                  </span>
                  <a
                    className="opacity_1 color-light-white text-decoration-none"
                    href="https://policies.google.com/privacy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  <span className="opacity-07"> and </span>
                  <a
                    className="opacity_1 color-light-white text-decoration-none"
                    href="https://policies.google.com/terms"
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                  <span className="opacity-07"> apply. </span>
                </span>
              </div>
            </div>
          </div>
          <div className="text-center color-light-white font-xxs opacity-07 border-top py-3">
            <small>
              Copyright {getCurrentYear} © Cyboryx, WLL. All Rights Reserved.
            </small>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
