import React from "react";
import { useParallax } from "react-scroll-parallax";
import StandardItem from "../StandardItem";
import { standardCardData } from "./Helper";

const Standard = () => {
  const { ref } = useParallax({ speed: 5 });
  return (
    <>
      <section className="min-vh-100 bg-white" id="company" ref={ref}>
        <div className="container py-5 my-md-5">
          <div className="row justify-content-center pb-md-5 pb-3">
            <div className="col-xl-7 col-11">
              <h2
                className="text-center ff_josfin color-light-black font-2xl "
                data-aos="fade-down"
              >
                CONQUER ADVERSE INDUSTRIAL CYBERSECURITY THREATS WITH CYBORYX’
                ETHOS
              </h2>
            </div>
          </div>

          <div className="row justify-content-center">
            {standardCardData &&
              standardCardData.map((obj, index) => (
                <StandardItem obj={obj} key={index} id={index} />
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Standard;
