import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import arrow from "../assets/images/svg/card-arrow.svg";
import { cyberSecurityData } from "./common/Helper";
const Card = () => {
  const [cardVideo, setCardVideo] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setCardVideo(true);
    }, 2500);
  }, []);

  return (
    <>
      <section className="overflow-hidden cards_section_solution bg_cards d-flex flex-column justify-content-center position-relative">
        <div className="w-100 position-absolute start-0 top-0 h-100 z_negative_1">
          <img
            className="w-100 object_fit_cover"
            height="100%"
            style={{ display: cardVideo ? "none" : "block" }}
            src="https://vz-31b52218-178.b-cdn.net/80fc7d0b-0873-4c9c-a90d-6fbc02473822/thumbnail_9cd82d1d.jpg"
            alt="thumbnail"
          />
          <ReactPlayer
            style={{ display: cardVideo ? "block" : "none" }}
            className="react-player"
            url="https://vz-31b52218-178.b-cdn.net/80fc7d0b-0873-4c9c-a90d-6fbc02473822/playlist.m3u8"
            width="100%"
            playing={cardVideo}
            loop={true}
            muted={true}
            playsinline
            height="100%"
          />
        </div>

        <div className="container py-5 ">
          <div className="row justify-content-center justify-content-lg-between h-100">
            <div className="col-md-10 col-lg-5">
              <h2
                className="font-xl fw-normal color-white ff_josfin card_heading text-center text-lg-start mb-4 "
                data-aos="fade-right"
              >
                KEY BENEFITS OF NEXT-GEN INDUSTRIAL CYBERSECURITY SOLUTIONS
              </h2>
            </div>
            <div className="col-lg-7 col-xl-6 h-100 d-flex flex-column justify-content-center">
              <div className="d-flex flex-column flex-sm-row justify-content-center Benefits_cards_parent">
                {cyberSecurityData.map((obj, i) => (
                  <div
                    className={`d-flex  ${
                      i === 0 || i === 2 ? "pt-sm-5 mt-sm-5" : ""
                    }`}
                    key={i}
                  >
                    <div className="mx-2 w_mobile_100">
                      {obj.parentCard.map((item, i) => (
                        <div
                          key={i}
                          data-aos="fade-up"
                          data-aos-duration="800"
                          data-aos-delay={`${(i + 1) * 300}`}
                        >
                          <div
                            className="Benefits_cards position-relative my-sm-3 mb-3"
                            key={i}
                          >
                            <h4 className="font-2xl mb-2 ">{item.number}</h4>
                            <p className="fs_18  color-light-black fw_500 ff_josfin mb-0 text-uppercase">
                              {item.text}
                            </p>
                            <a href="#">
                              <img
                                className="position-absolute card_arrow"
                                src={arrow}
                                alt="arrow"
                              />
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Card;
