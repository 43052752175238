import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ContactForm from "../components/ContactForm";
import ContactHero from "../components/ContactHero";

const Contact = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);
  return (
    <>
      <div className="bg-light-gray">
        <ContactHero />
        <div className="bg-light-gray py-5 overflow-hidden">
          <div className="container">
            <div className="row justify-content-center pb-4 py-sm-5">
              <div className="col-12 col-md-7">
                <h2
                  className="text-center ff_josfin color-light-black font-2xl px-lg-4 "
                  data-aos="fade-right"
                  data-aos-duration="400"
                  data-aos-delay="200"
                >
                  Whether It’s Partnership Related Or If It’s A Business Enquiry
                  -
                  <span className="color-dark-blue d-block">
                    WE’LL BE IN TOUCH!
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bg-light-white pb-5 position-relative"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="300"
        >
          <div className="container input_focus mb-md-5 pb-lg-5">
            <div className="row justify-content-center ">
              <div className="col-12 col-lg-7">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
