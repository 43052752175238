import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import pageLogo from "../assets/images/svg/pageLogo.svg";
import { HeaderData, HeaderDataMobile } from "./common/Helper";
import { LogoIcon } from "./common/Icons";
import { lenis } from "./common/SmoothScroll";

const Header = () => {
  const navigate = useNavigate();

  const BeforeDesktop = ({ children }) => {
    const isBeforeDesktop = useMediaQuery({ maxWidth: 991.98 });
    return isBeforeDesktop ? children : null;
  };
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };

  const handldeOverlayActive = () => {
    document.body.classList.toggle("active-nav-overlay");
  };

  const [yOffset, setYOffset] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  function handleScroll() {
    const currentYOffset = window.pageYOffset;
    const visible = yOffset > currentYOffset;

    setYOffset(currentYOffset);
    setVisible(visible);
  }
  const location = useLocation();
  return (
    <>
      <section
        className={`position-fixed nav_bg index_999 d-flex w-100  px-3 py-2 p-sm-3 ${
          yOffset > 650
            ? visible
              ? "navbar-scroll-active"
              : "navbar-hide"
            : ""
        }`}
      >
        <div className=" index_999 w-100 position-relative z-5 header_bg">
          <Desktop>
            <div className="container-fluid px-5 d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <div className="cursor_pointer pe-xxl-2 me-lg-3 me-xl-5">
                  <span
                    onClick={() => navigate("/")}
                    className="cyboryx-logo cursor-pointer"
                  >
                    <LogoIcon />
                  </span>
                </div>
                <div className="nav-list-items d-flex">
                  <ul className="list-unstyled d-flex align-items-center mb-0">
                    {HeaderData.map((val, i) => {
                      return (
                        <li className="ms-4 me-xxl-2" key={i}>
                          <span
                            onClick={() => {
                              if (location.pathname !== "/" && i !== 2) {
                                navigate(`/`);
                                setTimeout(() => {
                                  lenis.scrollTo(`/${val.link}`);
                                }, 1000);
                              } else if (i === 2) {
                                navigate(`/contact`);
                              } else {
                                lenis.scrollTo(`${val.link}`);
                              }
                            }}
                            className="text-uppercase text-decoration-none navbar-link font-xs fw-normal ff_lato text-white"
                          >
                            {val.text}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="d-flex">
                <div>
                  <svg
                    className="cursor-pointer search-icon"
                    onClick={() => navigate("/")}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5 14H14.71L14.43 13.73C15.4439 12.554 16.0011 11.0527 16 9.5C16 8.21442 15.6188 6.95772 14.9046 5.8888C14.1903 4.81988 13.1752 3.98676 11.9874 3.49479C10.7997 3.00282 9.49279 2.87409 8.23192 3.1249C6.97104 3.3757 5.81285 3.99477 4.90381 4.90381C3.99477 5.81285 3.3757 6.97104 3.1249 8.23192C2.87409 9.49279 3.00282 10.7997 3.49479 11.9874C3.98676 13.1752 4.81988 14.1903 5.8888 14.9046C6.95772 15.6188 8.21442 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14V14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
                      fill="white"
                    />
                  </svg>

                  <span
                    className="cursor_pointer nav_hover_underline position-relative text-uppercase mx-3 mx-xl-4 text-decoration-none  font-xs font-medium ff_lato text-white"
                    onClick={() => navigate("/contact")}
                  >
                    contact us
                  </span>
                  <span
                    onClick={() => navigate("/qatar")}
                    className="text-uppercase ms-xl-3  cursor-pointer text-decoration-none font-xs font-medium ff_lato color-light-black bg-white nav_btn"
                  >
                    Qatar national vision 2030
                  </span>
                </div>
              </div>
            </div>
          </Desktop>
          <BeforeDesktop>
            <div className="px-3 d-flex justify-content-between align-items-center">
              <div className="nav-log cursor_pointer z-index_100">
                <span onClick={() => navigate("/")} className="cyboryx-logo">
                  <LogoIcon />
                </span>
              </div>
              <div className="d-none d-lg-block">
                <svg
                  className="cursor-pointer search-icon"
                  onClick={() => navigate("/")}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5 14H14.71L14.43 13.73C15.4439 12.554 16.0011 11.0527 16 9.5C16 8.21442 15.6188 6.95772 14.9046 5.8888C14.1903 4.81988 13.1752 3.98676 11.9874 3.49479C10.7997 3.00282 9.49279 2.87409 8.23192 3.1249C6.97104 3.3757 5.81285 3.99477 4.90381 4.90381C3.99477 5.81285 3.3757 6.97104 3.1249 8.23192C2.87409 9.49279 3.00282 10.7997 3.49479 11.9874C3.98676 13.1752 4.81988 14.1903 5.8888 14.9046C6.95772 15.6188 8.21442 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14V14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div
                id="nav-icon1"
                className={`hamburger-menu`}
                onClick={() => handldeOverlayActive()}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>

            <div className={`navbar-overlay `}>
              <ul className="h-100 list-unstyled d-flex flex-column align-items-center justify-content-center m-0">
                {HeaderDataMobile.map((val, i) => {
                  return (
                    <li
                      className="mb-2"
                      onClick={() => handldeOverlayActive()}
                      key={i}
                    >
                      <a
                        onClick={() => {
                          if (location.pathname !== "/" && i !== 2) {
                            navigate(`/`);
                            setTimeout(() => {
                              lenis.scrollTo(`${val.link}`);
                            }, 500);
                          } else if (i === 2) {
                            navigate(`/contact`);
                          } else {
                            setTimeout(() => {
                              lenis.scrollTo(`${val.link}`);
                            }, 500);
                          }
                        }}
                        className="text-uppercase text-decoration-none font-xs font-normal ff_lato text-white"
                      >
                        {val.text}
                      </a>
                    </li>
                  );
                })}
                <li onClick={() => handldeOverlayActive()}>
                  <a
                    className="cursor_pointer d-block position-relative text-uppercase text-decoration-none font-xs font-medium ff_lato text-white"
                    onClick={() => navigate("/contact")}
                  >
                    contact us
                  </a>
                </li>
                <li onClick={() => handldeOverlayActive()} className="mt-4">
                  <span
                    onClick={() => navigate("/qatar")}
                    className="text-uppercase ms-xl-3  cursor-pointer text-decoration-none font-xs font-medium ff_lato color-light-black bg-white nav_btn d-block"
                  >
                    Qatar national vision 2030
                  </span>
                </li>
              </ul>
            </div>
          </BeforeDesktop>
        </div>
      </section>
    </>
  );
};

export default Header;
