import React from "react";

function Nationalvision() {
  return (
    <>
      <div className=" py-5 overflow-hidden bg-white">
        <div className="container">
          <div className="row justify-content-center pb-3 pt-lg-3 py-lg-5">
            <div
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="200"
              className="col-12 col-md-11 pt-4 pt-sm-5 pt-lg-0"
            >
              <h2 className="text-center ff_josfin color-light-black font-2xl text-uppercase mb-0 mb-sm-2 px-lg-3 mt-xl-5">
                CYBORX PROUDLY ALIGNS ITS STRATEGIC VISION WITH
                <span className="color-dark-blue d-xxl-block">
                  &nbsp; QATAR’S NATIONAL VISION 2030
                </span>
              </h2>
            </div>
          </div>
          <div className="row  justify-content-between pt-lg-5 mt-lg-3">
            <div data-aos="fade-right" className="col-12 col-lg-5">
              <p className="color-dark-blue font-md fw-bold text-uppercase text-center text-lg-start">
                Cyboryx Is An Accelerator Of QNV2030
              </p>
              <p className="font-md font-normal text-uppercase pe-xl-4 text-center text-lg-start">
                We Embody The Future Of Innovation In Workforce, Social Well
                Being, Economic And Environmental Sustainability.
              </p>
            </div>
            <div data-aos="fade-left" className="col-12 col-lg-5 ">
              <p className="color-dark-blue font-md fw-bold text-uppercase text-center text-lg-start">
                Cyboryx’ OT/IoT ICS Security
              </p>
              <p className="font-md font-normal text-uppercase pe-xl-5 text-center text-lg-start">
                Products and Services Offer Sector Specific Tailored
                Cybersecurity Solutions Across Multiple Complex Hybrid
                Platforms.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Nationalvision;
