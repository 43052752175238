import React, { useEffect, Suspense } from "react";
import { Parallax } from "react-parallax";
import { useLocation } from "react-router-dom";
import img1 from "../assets/images/webp/1.webp";
import img2 from "../assets/images/webp/8.webp";
import img3 from "../assets/images/webp/3.webp";
import img4 from "../assets/images/webp/4.webp";
import Card from "../components/Card";
import Standard from "../components/common/Standard";
import Director from "../components/Director";
import Gold from "../components/Gold";
import Hero from "../components/Hero";
import Solution from "../components/Solution";
const OurGoals = React.lazy(() => import("../components/OurGoals"));
const Cyboryx = React.lazy(() => import("../components/Cyboryx"));
const IndustrialSectors = React.lazy(() =>
  import("../components/IndustrialSectors")
);

const Home = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <>
      <div className="bg-white">
        <Hero />
        <Standard />
      </div>
      <Director />
      <Solution />
      <Parallax bgImage={img1} strength={200}>
        <div
          className="shadow_one position-relative"
          style={{ height: "60vh" }}
        ></div>
        <span className="parallax_text font-2xl text-uppercase ">
          Cyboryx <br />
          Qatar’s Infrastructure Shield.
        </span>
      </Parallax>
      <Gold />
      <Parallax
        bgImage={img2}
        strength={200}
        className="bg_position d-none d-lg-block"
      >
        <div className="shadow_one" style={{ height: "100vh" }}></div>
      </Parallax>
      <Parallax bgImage={img2} strength={200} className="bg_position d-lg-none">
        <div className="shadow_one" style={{ height: "60vh" }}></div>
      </Parallax>
      <Card />
      <Parallax bgImage={img3} strength={200}>
        <div className="shadow_one" style={{ height: "80vh" }}></div>
      </Parallax>
      <IndustrialSectors />
      <Parallax bgImage={img4} strength={200}>
        <div className="shadow_one" style={{ height: "60vh" }}></div>
        <span className="parallax_text font-2xl text-uppercase ">
          Cyboryx <br />
          Qatar’s Infrastructure Shield.
        </span>
      </Parallax>
      <Cyboryx />
      <OurGoals />
    </>
  );
};

export default Home;
