import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Human from "../assets/images/svg/human-devlopment.svg";
import Social from "../assets/images/svg/social-devlopment.svg";
import Ecnomic from "../assets/images/svg/economics-devlopment.svg";
import Envirment from "../assets/images/svg/enviromental-devlopment.svg";
function Devlepmenttypes() {
  const [developmentVideo, setDevelopmentVideo] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDevelopmentVideo(true);
    }, 2500);
  }, []);
  return (
    <>
      <div className="w-100 position-relative rose-video-height overflow-hidden">
        <div
          id=""
          className="position-relative rose-video-height overflow-hidden"
        >
          {" "}
          <img
            className="w-100 object_fit_cover"
            height="100%"
            style={{ display: developmentVideo ? "none" : "block" }}
            src="https://vz-31b52218-178.b-cdn.net/9ae62bcd-e7c1-485f-9c07-2a7def254730/thumbnail.jpg"
            alt="thumbnail"
          />
          <ReactPlayer
            style={{ display: developmentVideo ? "block" : "none" }}
            className="react-player"
            url="https://vz-31b52218-178.b-cdn.net/9ae62bcd-e7c1-485f-9c07-2a7def254730/playlist.m3u8"
            width="100%"
            playing={developmentVideo}
            loop={true}
            muted={true}
            height="100%"
            playsinline
          />
        </div>
        <div className="position-absolute top-50 start-50 translate-middle w-100 h-100 ">
          <div className="d-md-flex justify-content-md-end justify-content-center h-100 transform_devlepment">
            <div className="d-sm-flex w-100 justify-content-around bg-gradient-white mb-0 mb-sm-4 mb-md-0">
              <div
                data-aos="zoom-in"
                className="d-flex align-items-center justify-content-center devepmemt_card_hov w-100 mb-4 mb-sm-0 mx-auto text-center pt-4 pt-md-0 pb-2 pb-md-0"
              >
                <div className="d-flex gap-4 gap-sm-0 align-items-center d-sm-block">
                  <img className="width_adjust" src={Human} alt="Human" />
                  <h5 className="text-uppercase text-center color-white font-lg font-medium mt-sm-5">
                    human<span className="d-block"> development</span>
                  </h5>
                </div>
                {/* <div className='border_left'></div> */}
              </div>
              <div
                data-aos="zoom-in"
                className="d-flex flex-sm-column align-items-center gap-4 gap-sm-0 justify-content-center devepmemt_card_hov w-100 mb-4 mb-sm-0 mx-auto text-center pt-4 pt-md-0 pb-2 pb-md-0"
              >
                <img className="width_adjust" src={Social} alt="Social" />
                <h5 className="text-uppercase text-center color-white font-lg font-medium mt-sm-5">
                  Social<span className="d-block"> development</span>
                </h5>
              </div>
            </div>
            <div className="d-sm-flex w-100 justify-content-around mb-0 mb-sm-4 mb-md-0 pt-sm-4 pt-md-0">
              <div
                data-aos="zoom-in"
                className="d-flex d-sm-block d-md-flex flex-sm-column align-items-center justify-content-center gap-4 gap-sm-0 devepmemt_card_hov w-100 mb-4 mb-sm-0 mx-auto text-center pt-4 pt-md-0 pb-2 pb-sm-0"
              >
                <img className="width_adjust" src={Ecnomic} alt="Ecnomic" />
                <h5 className="text-uppercase text-center color-white font-lg font-medium mt-sm-5">
                  economic<span className="d-block"> development</span>
                </h5>
              </div>
              <div
                data-aos="zoom-in"
                className="d-flex d-sm-block gap-4 gap-sm-0 d-md-flex flex-sm-column align-items-center justify-content-center devepmemt_card_hov w-100  mx-auto text-center pt-4 pt-md-0 pb-4 pb-md-0"
              >
                <img className="width_adjust" src={Envirment} alt="Envirment" />
                <h5 className="text-uppercase text-center color-white font-lg font-medium mt-sm-5">
                  environmental<span className="d-block"> development</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Devlepmenttypes;
