import React, { useEffect } from "react";
import { Parallax } from "react-parallax";
import { useLocation } from "react-router-dom";
import img1 from "../assets/images/webp/5.webp";
import img4 from "../assets/images/webp/7.webp";
import { FloatingLogo } from "../components/common/Icons";
import Devlepmenttypes from "../components/Devlepmenttypes";
import Leading from "../components/Leading";
import Nationalvision from "../components/Nationalvision";
import QatarHero from "../components/QatartHero";

function Qatar() {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);
  return (
    <>
      <QatarHero />
      <Parallax bgImage={img1} strength={200}>
        <div
          className="shadow_one position-relative"
          style={{ height: "120vh" }}
        ></div>
        <span className="parallax_text  font-2xl text-uppercase">
          Cyboryx <br />
          Qatar’s Infrastructure Shield
        </span>
      </Parallax>
      <Nationalvision />
      <Devlepmenttypes />
      <Leading />
      <Parallax bgImage={img4} strength={200}>
        <div className="shadow_one" style={{ height: "60vh" }}></div>
        <span className="parallax_text svg_logo font-2xl text-uppercase">
          <FloatingLogo />
        </span>
      </Parallax>
    </>
  );
}

export default Qatar;
