import "./App.css";
import "./header.css";
import "./leading.css";
import "./layout.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./components/common/Footer";
import Contact from "./view/Contact";
import { Route, Routes } from "react-router-dom";
import Home from "./view/Home";
import Qatar from "./view/Qatar";
import { useScreenFixedProvider } from "../src/components/context/ScreenFixedProvider";
import { useEffect } from "react";
import Header from "./components/Header";
import "./components/common/SmoothScroll";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  AOS.init({
    once: true,
    duration: 800,
    delay: 50,
  });

  const { showOverlay } = useScreenFixedProvider();
  useEffect(() => {
    if (showOverlay) {
      document.body.classList.add("setClass");
    } else {
      document.body.classList.remove("setClass");
    }
  }, [showOverlay]);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/qatar" element={<Qatar />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
