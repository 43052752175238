import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

const Gold = () => {
  const [goldVedio, setGoldVedio] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setGoldVedio(true);
    }, 2500);
  }, []);

  return (
    <>
      <div className="gold_section d-flex align-items-center overflow-hidden position-relative pt-5 bg-white">
        <div className="container">
          <div className="row ">
            <div className="col-lg-5 mb-4 mb-lg-0">
              <div className="text-center text-lg-start">
                <h3
                  className="font-2xl font-normal ff_josfin color-light-black mb-2 pb-2"
                  data-aos="fade-right"
                  data-aos-duration="800"
                >
                  THE GOLD STANDARD
                </h3>
                <p
                  data-aos="fade-right"
                  data-aos-duration="800"
                  data-aos-delay="300"
                  className="opacity-07 ff_lato fw-normal font-md color-light-black pe-lg-3 pe-xl-5 me-xxl-5"
                >
                  We set a high bar in the industrial cybersecurity space
                  where we bring the best workforce and develop The Top Of The
                  Line Next-Gen Products And Services that go with our own
                  individually crafted in-house Cybersecurity programs for each
                  industrial sector's OT/IoT environment.
                </p>
              </div>
            </div>
            <div className="col-lg-7 gold_video_static position_lg_absolute end-0 ">
              <div className=" text-center">
                {" "}
                <img
                  className="w-100 object_fit_cover"
                  height="100%"
                  style={{ display: goldVedio ? "none" : "block" }}
                  src="https://vz-31b52218-178.b-cdn.net/408309d8-facd-497c-bbb8-c07a0069a8db/thumbnail.jpg"
                  alt="thumbnail"
                />
                <ReactPlayer
                  style={{ display: goldVedio ? "block" : "none" }}
                  className="object_fit_cover gold_section_video"
                  url="https://vz-31b52218-178.b-cdn.net/408309d8-facd-497c-bbb8-c07a0069a8db/playlist.m3u8"
                  width="100%"
                  playing={goldVedio}
                  loop={true}
                  muted={true}
                  playsinline
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gold;
