import emailjs from "@emailjs/browser";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

function ContactForm() {
  emailjs.init("cS9Pd_2IyUfnJVDmF");
  const initialState = {
    fullname: "",
    email: "",
    phone: "",
    areaofinterest: "",
    message: "",
    profile: "",
  };
  const serviceId = "service_4rro2sn";
  const templateId = "template_xwxhh5c";
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [contactForm, setContactForm] = useState(initialState);
  const [error, setError] = useState(false);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setError(true);
    setLoading(true);
    if (
      contactForm.fullname &&
      contactForm.email &&
      contactForm.phone &&
      contactForm.areaofinterest &&
      emailRegex.test(contactForm.email) &&
      contactForm.message
    ) {
      setError(false);

      emailjs
        .send(serviceId, templateId, {
          fullName: contactForm.fullname,
          email: contactForm.email,
          areaOfIntrest: contactForm.areaofinterest,
          number: contactForm.phone,
          message: contactForm.message,
          profile: contactForm.profile,
          "g-recaptcha-response": token,
        })
        .then((res) => {
          setLoading(false);
          console.log("successfully sent!", res);
          toast("Successfully sent!", {
            position: "bottom-right",
            autoClose: 2000,
          });
          setContactForm(initialState);
        })
        .catch((err) => {
          console.log(err, "error");
          setLoading(false);
          toast.error(err.text, {
            position: "bottom-right",
            autoClose: 2000,
          });
        });
    }
  };

  return (
    <>
      <div className="form_bg_clases mx-md-4 pt-4 pb-4 px-3 px-md-5">
        <div className="position-relative">
          <input
            onChange={(e) =>
              setContactForm({ ...contactForm, fullname: e.target.value })
            }
            type="text"
            placeholder="Full Name"
            className="w-100 input-common-clases mb-3 mt-3"
            value={contactForm.fullname}
          />

          {error && contactForm.fullname === "*Required" && (
            <span className="required_text">*Required</span>
          )}
        </div>
        <div className="position-relative">
          <input
            onChange={(e) =>
              setContactForm({ ...contactForm, email: e.target.value })
            }
            type="email"
            placeholder="Email*"
            value={contactForm.email}
            className="w-100 input-common-clases mb-3 mt-3"
          />

          {error &&
            (contactForm.email === "" ? (
              <span className="required_text">*Required</span>
            ) : error && emailRegex.test(contactForm.email) === false ? (
              <span className="required_text">Invalid email</span>
            ) : (
              ""
            ))}
        </div>

        <div className="position-relative">
          <input
            onChange={(e) =>
              setContactForm({ ...contactForm, phone: e.target.value })
            }
            type="number"
            placeholder="Phone Number*"
            value={contactForm.phone}
            className="w-100 input-common-clases mb-3 mt-3"
          />
          {error && contactForm.phone === "" && (
            <span className="required_text">*Required</span>
          )}
        </div>

        <div className="position-relative">
          <input
            onChange={(e) =>
              setContactForm({ ...contactForm, profile: e.target.value })
            }
            type="text"
            placeholder="LinkedIn Profile"
            value={contactForm.profile}
            className="w-100 mt-3 mb-2 input-common-clases"
          />
        </div>
        <div className="position-relative">
          <select
            onChange={(e) =>
              setContactForm({ ...contactForm, areaofinterest: e.target.value })
            }
            className="input-common-clases w-100 mb-4 mt-3"
            value={contactForm.areaofinterest}
          >
            <option defaultValue="Area of Interest" value="" disabled>
              Area of Interest
            </option>
            <option value="General Enquiry">1. General Enquiry </option>
            <option value="Partnership">2.Partnership </option>
            <option value="Sales">3. Sales </option>
            <option value="Demo">4. Demo </option>
          </select>
          {error && contactForm.areaofinterest === "" && (
            <span className="required_text2">*Required</span>
          )}
        </div>

        <div className="position-relative">
          <textarea
            onChange={(e) =>
              setContactForm({ ...contactForm, message: e.target.value })
            }
            className="input-common-clases w-100 my-2"
            placeholder="Message*"
            cols="10"
            rows="2"
            value={contactForm.message}
          ></textarea>
          {error && contactForm.message === "" && (
            <span className="required_text3">*Required</span>
          )}
          <p className="text-black opacity-07 text-end mt-2 mb-0">
            * Required fields
          </p>
        </div>

        <div className="mb-xl-5 mb-3 pb-1">
          <GoogleReCaptcha
            onVerify={(token) => {
              setToken(token);
            }}
          />
        </div>
        <div className="text-center mt-3 mb-2">
          <button
            onClick={(event) => handleSubmit(event)}
            type="submit"
            disabled={loading}
            className="submit-btn color-white font-medium font-xs"
          >
            {loading ? "LOADING..." : "SUBMIT"}
          </button>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
