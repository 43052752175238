import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import leadingHourse from "../assets/images/svg/leading_hourse.svg";
import hourse_man from "../assets/images/webp/hourse_man.webp";

const Leading = () => {
  const [leadignVedio, SetLeadingVedio] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      SetLeadingVedio(true);
    }, 2000);
  }, []);
  return (
    <>
      {/* =========LEADING-SECTION======= */}
      <section className="h_lg_100vh m_top_5 overflow-hidden">
        <div className="d-lg-flex">
          <div className="w-100 h-100">
            <div className="position-relative leading_hourse_height w-100">
              {" "}
              <img
                className="w-100 object_fit_cover h_lg_100vh"
                height="100%"
                style={{ display: leadignVedio ? "none" : "block" }}
                src="https://vz-31b52218-178.b-cdn.net/d36af12e-3928-432a-a3d3-229a472db98b/thumbnail.jpg"
                alt="thumbnail"
              />
              <ReactPlayer
                style={{ display: leadignVedio ? "block" : "none" }}
                className=" leading_box_img h_lg_100vh "
                url="https://vz-31b52218-178.b-cdn.net/d36af12e-3928-432a-a3d3-229a472db98b/playlist.m3u8"
                width="100%"
                playing={leadignVedio}
                loop={true}
                playsinline
                muted={true}
              />
            </div>
          </div>
          <div className=" h_lg_100vh leading_text_box d-flex align-items-center position-relative bg_dark_blue px-2 px-sm-3 px-xzl-5 w-100 py-5 pb-lg-0 text-center text-lg-start">
            <img
              width={120}
              height={165}
              className="position-absolute leading_position"
              src={leadingHourse}
              alt="leadingHourse"
            />
            <div className="ps-xxl-4 leading_para_width  py-sm-5 py-lg-0 pb-xxl-5">
              <h3 className="color-light-white font-lg font-medium ff_josfin mb-2 mb-sm-4 pb-2">
                CYBORYX ACCELERATION
              </h3>
              <p className="opacity-07 fw-normal color-light-white font-sm mb-lg-4 opacity_hover_1">
                A Sustainable Footprint, Developing Qatar’s ICS Security
                Workforce, Professionally Contributing to Our Society’s
                Prosperity.
              </p>
              <p className="opacity-07 fw-normal color-light-white font-sm mb-lg-4 opacity_hover_1">
                Leading The Workforce To Be Competitive With The Top ICS
                Professionals In The World.
              </p>
              <p className="opacity-07 fw-normal color-light-white font-sm mb-lg-4 opacity_hover_1">
                Keeping Qatar’s Citizens & Residents At Peace with Our Resilient
                ICS Security Posture.
              </p>
              <p className="opacity-07 fw-normal color-light-white font-sm mb-lg-4 opacity_hover_1">
                Developing The Best In Class Technology In Industrial Security
                For Global Partnerships, Diversified Economy And Global
                Compliance Standards.
              </p>
              <p className="opacity-07 fw-normal color-light-white font-sm mb-lg-4 opacity_hover_1">
                Securing A High Standard Of Living For Qatar’s Citizens &
                Residents, For The Present & Future By Utilizing Cyboryx ICS
                Solutions Securing Qatar’s Economy.
              </p>
              <p className="opacity-07 fw-normal color-light-white font-sm mb-lg-4 opacity_hover_1">
                Global Partnerships With Top International Educational
                Institutions And Enterprises Specialized In OT Automation And
                ICS Security.
              </p>
              <p className="opacity-07 fw-normal color-light-white font-sm mb-0 mb-lg-4 opacity_hover_1">
                Leading Industrial Control Security, Achieving Harmony Between
                Our Economy Growth, Social Development And Environmental
                Protection.
              </p>
            </div>
          </div>{" "}
        </div>
      </section>
    </>
  );
};

export default Leading;
