import React from "react";
import { solutionData } from "./common/Helper";
import SolutionFaq from "./SolutionFaq";

const Solution = () => {
  return (
    <>
      <section id="solutions">
        <div
          className=" d-lg-flex flex_wrap solution_card position-relative d-none"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <h2 className="center_text ff_josfin">
            <span data-aos="zoom-in">
              STAY A STEP AHEAD WITH CYBORYX ICS SOLUTIONS
            </span>
          </h2>

          {solutionData.map((obj, index) => (
            <div
              key={index}
              className={`${obj.image} custom_width_solution ps-lg-5 pe-lg-4 pt-lg-4 p-md-4 px-2 pt-3`}
            >
              <div>
                <div className="position-relative heading_line">
                  <h2
                    data-aos="fade-right"
                    data-aos-duration="800"
                    data-aos-delay={`${(index + 1) * 300}`}
                    className="text-white font-md font-medium mt-lg-3 position-relative z_index_9999 "
                  >
                    {obj.title}
                  </h2>
                  <div className="mw_solution_para">
                    <p className="text-white card-hover-para ">{obj.desc}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-lg-none bg-white">
          <SolutionFaq />
        </div>
      </section>
    </>
  );
};

export default Solution;
