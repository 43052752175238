import React from "react";
import directoryImg from "../assets/images/svg/directory_img.svg";

const Director = () => {
  return (
    <>
      <div className="bg-light-white py-xl-4" id="partners">
        <div className="py-lg-3">
          <div className="container py-md-5 py-4 d-flex align-items-center justify-content-center flex-column">
            <img
              data-aos="zoom-in"
              height={64}
              width={54}
              className="w-100"
              src={directoryImg}
              alt="directoryImg"
            />
            <p
              data-aos="zoom-in"
              className="ff_lato font-sm fw-normal color-light-black opactiy_8 text-center directory_para_width mx-auto my-4 pt-lg-2"
            >
              “If we are to solve tomorrow’s challenges, we must deal with them
              today, no matter how complex they may seem. At Cyboryx, we address
              some of the most pressing industrial automation technological
              challenges by bringing together a force of the brightest minds
              committed to reimagining what a secure and sustainable Qatar
              should look like, and building it today, securing tomorrow - We
              are redefining the future now."
            </p>
            <p
              data-aos="zoom-in"
              className="ff_josfin text-uppercase color-light-black opactiy_8 font-lg font-medium mb-0 text-center"
            >
              Cyboryx Board of Directors
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Director;
