import React, { useEffect, useState } from "react";

import ReactPlayer from "react-player";
import { useParallax } from "react-scroll-parallax";

const StandardItem = ({ obj, id }) => {
  return (
    <>
      <div
        className={obj.colClass_second}
        data-aos="fade-up"
        data-aos-delay={`${(id + 1) * 300}`}
      >
        <div className="position-relative index_1 hero_text overflow-hidden">
          <ReactPlayer
            className="react-player"
            url={obj.videoUrl}
            width="100%"
            height="100%"
            playing
            loop={true}
            playsinline
            volume={0}
            muted={true}
          />
          <div className="postioning_text position-absolute text-center px-3 ">
            <h3 className="ff_josfin text-uppercase text-white fw-normal mb-0 ps-3 font-medium ">
              {obj.para2}
            </h3>
            <p className="ff_lato text-capitalize text-white font-sm opacity_8 mb-0  font-normal pt-2 ">
              {obj.para3}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default StandardItem;
